const MenuItems = [
    {
        title: 'Search',
        link: '/'
    },

    {
        title: 'About The expertise platform',
        link: '/about'
    },

    {
        title: 'Contact Us',
        link: '/contact-us'
    },

];


export default MenuItems;
